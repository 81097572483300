import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router-dom';
import { CaseViewer } from 'src/components/cases';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useTimeout from 'src/hooks/useTimeout';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import useAPIEffect from 'src/lib/useAPIEffect';
import { Feed, User } from 'src/types/dataTypes';
import { Typography } from '@barracuda-internal/bds-core';
import ulog from 'ulog';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';

export default function CasePage() {
	const toolchain = useAppToolchain();
	const [feed, setFeed] = useState<Feed>();
	const [fetchingFeeds, setFetchingFeeds] = useState(true);
	const classes = useStyles();
	// TODO: Should be abstracted along with Dashboard Route
	const [user, setUser] = useState<User>();

	const [mustAuth, setMustAuth]: [
		boolean,
		React.Dispatch<React.SetStateAction<boolean>>,
	] = useState(false);

	const [loadError, setLoadError]: [Error, React.Dispatch<Error>] =
		useState();

	const { api } = toolchain;
	let { id } = useParams<{ id: string }>();

	const [notFound, setNotFound] = useState(false);

	// First we must get the user. We don't cache this information because we also use
	// it as a check to see if the user is authenticated.
	// If they are not, it will fail with a 401.
	const [isLongLoad, clearTimer] = useTimeout(1000, true);
	useAPIEffect((ignore, signal) => {
		api.getLoggedInUser({ signal })
			.then((user) => !ignore && setUser(user))
			.catch((err) => {
				if (ignore) return;
				if (err.status === 401) setMustAuth(true);
				else setLoadError(err);
			})
			.finally(() => clearTimer());
	}, []);

	useAPIEffect(
		(cancelled, signal) => {
			if (user) {
				setFetchingFeeds(true);
				api.getFeed(id, { signal }).then((feedData) => {
					ulog.info(`Feed retrieved`);
					setFeed(feedData);
				}).catch((err) => {
					if (err.response.status === 404 ) {
						setNotFound(true);
					}
				}).finally(() => {
					setFetchingFeeds(false);
				});
			}
		},
		[user],
	);
	if (mustAuth) {
		return <Redirect to="/bcclogin" />;
	}

	if (fetchingFeeds || !user) {
		return <LoadingIndicator message="Loading" />;
	}

	
	if( notFound ){
		return (<>
				<Typography variant="h3" color="textPrimary" className={classNames(classes['text_center'], classes['p_4'],classes['m_4'])}>
				This is an old link. Please look at the newer detection for this file on the Detections page
					</Typography>
		</>)
	}

	return (
		<>
			<Helmet>
				<title>Case {feed.id}</title>
			</Helmet>
			<Paper style={{ width: '100%' }}>
				<CaseViewer caseData={feed} />
			</Paper>
		</>
	);
}
