import React, { useContext, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    Box,
    IconButton

} from '@barracuda-internal/bds-core';

import cudaLogo from 'src/static/barracuda_logo_text.svg';
import iconCorrect from 'src/static/icon_correct.svg';
import Close from '@mui/icons-material/Close';
import useStyles from 'src/styles/di-theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { SideDetailContext } from './SideDetailPanel';

const feedbackStatusMapper = {
    success: {
        headerText: `Thank you for your feedback`,
        infoText: `The team will review the report and any additional details you've provided to improve on how detections like this are caught in the future.`
    },
    error: {
        headerText: `Error on submitting the feedback`,
        infoText: `Something went wrong while submitting the feedback. Please try again after sometime`
    }
};

export default function FeedbackConfirm({
    show = false,
    closePopup = () => { },
}) {
    const classes = useStyles();
    const {feedbackStatus} = useContext(SideDetailContext);

    return (
        <div>
            <Dialog open={show} onClose={() => { }}>
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <img
                            src={cudaLogo}
                            alt="Barracuda Data Inspector"
                            height={'36px'}
                        />

                        <Box sx={{ flexGrow: 1 }}></Box>

                        <IconButton
                            aria-label="Close"
                            onClick={() => {
                                closePopup();
                            }}
                            edge="end"
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '15px',
                            alignItems: 'center'
                        }}
                    >
                        {feedbackStatus === 'success' &&<img
                            src={iconCorrect}
                            className={classes['mr_1']}
                            alt="Barracuda Data Inspector"
                            height={'50px'}
                        />}

                        { feedbackStatus === 'error' && <ErrorOutlineIcon color="error" sx={{ height: 50, width: 50 }} /> }

                        <Typography variant='h3' style={{marginLeft: '7px'}}> 
                            {feedbackStatusMapper[feedbackStatus].headerText}
                        </Typography>
                    </Box>
                    <Typography>{feedbackStatusMapper[feedbackStatus].infoText}</Typography>

                </DialogContent>
                <DialogActions className={classes['m_1']}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            closePopup();
                        }}
                        fullWidth
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
