import React, { useRef, useEffect, useCallback, useContext } from 'react';
import { Box } from '@barracuda-internal/bds-core';
import { ImageLoader } from './ImageLoader';
import { debounce } from '@mui/material';
import { SideDetailContext } from './SideDetailPanel';

const ImageViewer = ({ previews,zoom, selectedClassiferObj, myRef,accessToken, viewingPage, generatePreviewWidthAndHeight, visiblePreviews, setVisiblePreviews, isViewable, setSelectedClassifierObj, getLocation, selectedClassifer, loadedImageRef, imageSpecs, classifierIdx, pageNo, setShowFBPopup}) => {
	const prevPage = useRef(0);
	const preloadImageRef = useRef({});
	const {timeStamp, imageRefs, setImageRefs, showRedactions, scrollIndex} = useContext(SideDetailContext);

	const preloadImages = (startIdx, endIdx, currShowRedactions) => {
		if(!isViewable) return;

		const imageUrlsToPreload = previews.slice(startIdx, endIdx);
		imageUrlsToPreload.forEach(preview => {
			if(!preloadImageRef.current[preview.id]) {
				const img = new Image();
				img.src = !currShowRedactions ? `${preview.url}?access_token=${accessToken}&t=${timeStamp}` : `${preview.url}/reveal?access_token=${accessToken}&t=${timeStamp}`;
				preloadImageRef.current[preview.id] = true;
			}
		});
	};

	useEffect(() => {
		preloadImages(0, 3, showRedactions);
		setImageRefs(
			visiblePreviews.reduce((acc, value, index) => {
				acc[index] = React.createRef();
				return acc;
			}, {})
		);
	}, []);

	const debouncedImageLoader = useCallback(
		debounce((index, currShowRedactions) => {
			loadImages(index, currShowRedactions);

			if(index - 1 > 0) {
				loadImages(index - 1, currShowRedactions);
			}
			if(index + 1 < previews.length) {
				loadImages(index + 1, currShowRedactions);
			}
		}, 500),
		[showRedactions]
	);

	const loadImages = useCallback((index, currShowRedactions) => {
		const preview = previews[index];
		const id = preview.id;

		if(loadedImageRef.current[id]) return;

		preloadImages(index, index + 1, currShowRedactions);

		setVisiblePreviews((prev) => {
			loadedImageRef.current[id] = true;
			return prev.map((prevItem, i) => {
				if (i === index && previews[index]?.url !== '') {
					return { 
						...prevItem, 
						url: previews[index].url ,
						redactedURL: `${previews[index].url }?access_token=${accessToken}&t=${timeStamp}`,
						revealedURL: `${previews[index].url }/reveal?access_token=${accessToken}&t=${timeStamp}`
					};
				} else {
					return prevItem;
				}
			});
		});
	}, [showRedactions])

	useEffect(() => {
		const callback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const page = entry.target.dataset.alt
					viewingPage(page);
	
					const currentPage = parseInt(page);
					const index = currentPage - 1;

					if(prevPage.current !== currentPage) {
						prevPage.current = currentPage;
						debouncedImageLoader(index, showRedactions);
					}
				}
			});
		};
	
		const options = {
			root: null,
			rootMargin: '-100px',
			threshold: 0.5,
		};
	
		const observer = new IntersectionObserver(callback, options);
	
		Object.values(imageRefs).forEach((ref) => observer.observe(ref.current));
	
		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [visiblePreviews, showRedactions, imageRefs]);

	useEffect(() => {
		scrollToSection(scrollIndex, imageRefs);
	}, [scrollIndex])
	
	const scrollToSection = (index, imageRefs) => {
		if (!imageRefs || !imageRefs[index]) return;
		imageRefs[index]?.current?.scrollIntoView();
	};

	return (
		<div style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
			{visiblePreviews.map((preview,index) => (
				<Box
				key={index}
					sx={{
						width: 600,
						maxWidth: 600,
						display: 'block',
						margin: 'auto',
						overflow: 'auto',
					}}
				>
					<ImageLoader 
						preview={preview}  
						index={index}
						selectedClassiferObj={selectedClassiferObj}
						myRef={myRef}
						generatePreviewWidthAndHeight={generatePreviewWidthAndHeight}
						imageRefs={imageRefs}
						zoom={zoom}
						setSelectedClassifierObj={setSelectedClassifierObj}
						getLocation={getLocation}
						selectedClassifer={selectedClassifer}
						imageSpecs={imageSpecs}
						classifierIdx={classifierIdx}
						previews={previews}
						setShowFBPopup={setShowFBPopup}
					/>
				</Box>
			))}
		</div>
	);
};

export default ImageViewer;
