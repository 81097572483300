import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Radio,
    RadioGroup,
    Button,
    Box,
    IconButton,
} from '@barracuda-internal/bds-core';
import cudaLogo from 'src/static/barracuda_logo_text.svg';
import Close from '@mui/icons-material/Close';
import { FormControlLabel, TextField, styled } from '@material-ui/core';
import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { SideDetailContext } from './SideDetailPanel';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { Alert } from '@material-ui/lab';

const feedbackOptions = [
    {
        label: 'Incorrect detection',
        value: 'IncorrectRedaction',
        isDesc: true
    },
    {
        label: 'Ignore and remove this redaction',
        value: 'IgnoreRedaction',
        isDesc: false
    },
    {
        label: 'Something else',
        value: 'SomethingElse',
        isDesc: true
    }
];

const StyledTextField = styled(TextField)({
    '&.MuiFormControl-marginNormal': {
        margin: '0px',
    }
});




export default function FeedbackPopup({
    show = false,
    closePopup = (state) => { },
}) {
    const {
        feedBackFinding, 
        setFeedbackFinding, 
        setFeedbackStatus, 
        setVisiblePreviews,
        selectedClassifer,
        setSelectedClassifier,
        classifierIdx,
        setClassifierIdx,
        setClassiferTotal,
        setClassifierTags,
        accessToken,
        zoom,
        showRedactions,
        refreshEndDate,
        totalViolations,
        setTotalViolations,
        onExpand,
        onClose,
        setShowFBConfirm,
        pickFinding,
        scrollIndex,
        isSharingClassifier,
        validFindingFromRedaction,
        sharingClassifierRef
    } = useContext(SideDetailContext);

    const classes = useStyles();
    const { api } = useAppToolchain();
    const [feedback, setFeedback] = useState(feedbackOptions[0].value);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleRadioChange = (event) => {
        setFeedback(event.target.value);
        setAdditionalInfo('');
    };

    const handleAdditionalInfoChange = (event) => {
        setAdditionalInfo(event.target.value);
    };

    const submitFalsePositiveFeedback = async () => {
        setLoading(true);
    
        const splitUrl = feedBackFinding.previewUrl.split('api');
        const urlWithSnapshotAndPreviewID = splitUrl[splitUrl.length - 1];
        const { id: userId } = auth.user;
        const {
            findingId,
            findingType,
            findingTag: findingClassification,
            topLeftCoordinate,
            bottomRightCoordinate,
            previewIndex,
        } = feedBackFinding;
    
        const reqPayload = {
            category: "FalsePositive",
            subCategory: feedback,
            details: additionalInfo,
            userId,
            findingId,
            findingType,
            findingClassification,
            topLeftCoordinate,
            bottomRightCoordinate
        };
    
        try {
            const { feedId, feedResolved } = await api.submitRedactionFeedback(urlWithSnapshotAndPreviewID, reqPayload);
    
            if (!feedId || feedId.length === 0) throw new Error("Feedback submission failed!");
    
            if ((feedResolved || totalViolations === 1) && validFindingFromRedaction) {
                handleSingleViolation();
            } else {
                await handleMultipleViolations(feedId, previewIndex);
            }

            if(validFindingFromRedaction) setTotalViolations(prev => prev - 1);
            setFeedbackStatus('success');
        } catch (err) {
            setFeedbackStatus('error');
            console.error(err.message);
        } finally {
            resetFormState();     
        }
    };
    
    const handleSingleViolation = () => {
        onExpand(false);
        refreshEndDate();
        onClose();
        setAdditionalInfo('');
        setShowFBConfirm(true);
    };
    
    const handleMultipleViolations = async (feedId, previewIndex) => {
        const { previews, tags } = await api.getFeedV2(feedId);
    
        if (!previews || !tags || previews.length === 0 || tags.length === 0) {
            throw new Error("Feedback submission failed!");
        }
    
        const timeStamp = new Date().getTime();
    
        setVisiblePreviews((prev) => {
            return prev.map((prevItem, i) => {
                if (i === previewIndex) {
                    return { 
                        ...previews[previewIndex],
                        redactedURL: `${prevItem.url }?access_token=${accessToken}&t=${timeStamp}`,
                        revealedURL: `${prevItem.url }/reveal?access_token=${accessToken}&t=${timeStamp}`
                    }
                } else {
                    return prevItem;
                }
            });
        });
    
        setClassifierTags(tags.filter(tag => !isSharingClassifier(tag.text)));
    
        const selectedClassiferIndex = tags.findIndex(item => item.text === selectedClassifer);
        checkNextFinding(selectedClassiferIndex, tags, previews);
        preloadImage(previewIndex, previews, timeStamp, showRedactions);
    };
    
    const resetFormState = () => {
        closePopup(true);
        setLoading(false);
        setFeedbackFinding({});
        setAdditionalInfo('');
    };
    
    

    const checkNextFinding = (selectedClassiferIndex, tags, previews) => {
        const shouldCallNextFinding = scrollIndex === feedBackFinding.previewIndex;

        if (selectedClassiferIndex !== -1) {
            const { count: updatedClassifierTotal } = tags[selectedClassiferIndex];
            setClassiferTotal(updatedClassifierTotal);
            let idx = classifierIdx % updatedClassifierTotal;
            if (idx == 0) idx = updatedClassifierTotal;
            setClassifierIdx(idx);
    
            if(shouldCallNextFinding) pickFinding(selectedClassifer, idx, zoom, previews);
            return;
        }
    
        const tag = tags.find(tag => !isSharingClassifier(tag.text));

        if(tag == null) return;

        const { text, count } = tag;
        setSelectedClassifier(text);
        setClassifierIdx(1);
        setClassiferTotal(count);
    
        if(shouldCallNextFinding) pickFinding(text, 1, zoom, previews);
    }
    

    const preloadImage = (idx, previews, timeStamp, currShowRedactions) => {
        const preview = previews[idx];
        const img = new Image();
        img.src = !currShowRedactions ? 
                    `${preview.url}?access_token=${accessToken}&t=${timeStamp}` : 
                    `${preview.url}/reveal?access_token=${accessToken}&t=${timeStamp}`;
	};

    return (
        <div>
            <Dialog open={show}>
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <img
                            src={cudaLogo}
                            alt="Barracuda Data Inspector"
                            height={'36px'}
                        />

                        <Box sx={{ flexGrow: 1 }}></Box>
                        <IconButton
                            aria-label="Close"
                            onClick={() => {
                                setFeedbackFinding({});
                                setAdditionalInfo('');
                                closePopup(false);
                            }}
                            edge="end"
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h2" style={{fontFamily: 'Roboto', fontWeight: 400, fontSize: '32px', marginBottom: '20px'}}>
                        Detection feedback
                    </Typography>
                    <Typography variant="body1">
                    Select the reason for removing the detection. Your feedback helps improve the accuracy of our data classification.
                    </Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        color='primary'
                        value={feedback}
                        onChange={handleRadioChange}
                    >
                        {
                            feedbackOptions.map((option, index) => (
                                <div key={index}>
                                    <FormControlLabel 
                                        value={option.value} 
                                        control={<Radio color='primary' />} 
                                        label={option.label} 
                                    />
                                    {
                                        option.isDesc && feedback === option.value && (
                                            <StyledTextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                minRows={1}
                                                helperText="(Optional) Please provide correct detection type if it applies."
                                                FormHelperTextProps={{
                                                    style: {
                                                        color: '#828282',
                                                        fontWeight: 400,
                                                    },
                                                }}
                                                onChange={handleAdditionalInfoChange}
                                                value={additionalInfo}
                                                size="small"
                                            />
                                        )
                                    }
                                </div>
                                
                            ))
                        }
                    </RadioGroup>
                </DialogContent>

                {
                    totalViolations === 1 && validFindingFromRedaction && sharingClassifierRef.current.length === 0 && 
                        <Alert severity="info">
                            This is the last finding on this document. Successful submission of feedback will resolve this Detection and move it under Resolved tab on the Detections page.
                        </Alert>
                }

                {
                    totalViolations === 1 && validFindingFromRedaction && sharingClassifierRef.current.length > 0 && 
                        <Alert severity="info">
                            This is the last finding on this document. Successful submission of feedback will close the preview.
                        </Alert>
                }

                {loading && <LoadingIndicator style={{padding: '10px'}} message="Submitting feedback" />}
                {!loading && <DialogActions className={classes['m_1']}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={false}
                        onClick={submitFalsePositiveFeedback}
                    >
                        Submit
                    </Button>
                </DialogActions>}
            </Dialog>
        </div>
    );
}
